import { Application } from "@hotwired/stimulus";
var application;
if (!window.stimulusInitialized) {
  window.stimulusInitialized = true;
  application = Application.start();
  console.log("Stimulus initialized");

  // Configure Stimulus development experience
  application.debug = false;
  window.Stimulus = application;
} else {
  application = window.Stimulus;
}
export { application };